import React from "react";
import Text from "./Text";
import { Icon } from "@iconify/react";
import Link from "next/link";

export default function MoreInfo({ title, href }) {
  return (
    <div className="flex flex-row items-center justify-end w-full">
      <Link href={href}>
        <a className="flex flex-row items-center">
          <Text text={title} inputFontS={true} className="underline" />
          <Icon
            icon={"ic:round-plus"}
            height={24}
            width={24}
            className="mr-2"
          />
        </a>
      </Link>
    </div>
  );
}
