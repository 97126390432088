"use client";
import Image from "next/image";
import React from "react";
import Text from "./Text";

const Modal = ({
  id,
  title = "",
  info = "",
  component,
  onClose,
  rightTitle = false,
  lang = "ar",
  classname,
  html,
  image,
  infoComp,
}) => {
  return (
    <dialog id={id} className="modal">
      <div
        style={{
          padding: image ? "0" : "2rem",
        }}
        className={`modal-box bg-white relative ${
          classname ? classname : `xl:w-3/4 w-4/5 xl:max-w-[45%]`
        } `}
      >
        <div
          className={`modal-action ${
            image ? `absolute top-5 mx-5 left-0` : ""
          } mt-0`}
        >
          <form onSubmit={() => onClose && onClose()} method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button>
              <Image
                src={require("/public/xIcon.svg")}
                width={30}
                height={30}
                alt={`oea-back`}
                className={`absolute z-10 bg-white rounded-btn top-5 cursor-pointer ${
                  lang === "ar" ? "left-5" : "right-5"
                }`}
              />
            </button>
          </form>
        </div>
        {title ? (
          <Text
            bold
            sectionTitle
            className={`${
              rightTitle ? "" : "text-center"
            } text-orange absolute top-5 left-0 right-0`}
            text={title}
          />
        ) : null}
        {infoComp ? (
          infoComp
        ) : info ? (
          <Text
            titleColors
            className="py-4 text-center px-5 md:px-20"
            text={info}
          />
        ) : null}
        {html ? (
          <Text
            isHtml
            text={html}
            className="text-infoText md:text-normal mt-8"
          />
        ) : null}
        {component ? component : null}
      </div>
    </dialog>
  );
};

export default Modal;
