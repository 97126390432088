import Banner from "components/Banner";
import MoreInfo from "components/MoreInfo";
import NewsBar from "components/NewsBar";
import SEO from "components/SEO";
import SliderNews from "components/SliderNews";
import Text from "components/Text";
import SessionContext from "components/session/SessionContext";
import { pagePadding } from "data/commonClasses";
import {
  fetchFromAPI,
  fetchParallel,
  getTranslationContent,
  handleRouter,
  isArray,
} from "functions";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import Image from "next/image";
import Router from "next/router";
import { useContext, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import useTranslation from "translations/hooks/useTranslation";

const DynamicModal = dynamic(() => import("components/Modal"), {
  ssr: false,
});
export default function Home({
  news,
  announcements,
  ads,
  introModalData,
  specialNews,
}) {
  const { t } = useTranslation();

  let {
    actions: { setSession },
    session: { lang, modalShown },
  } = useContext(SessionContext);
  // let isAr = lang === "ar";
  useEffect(() => {
    if (!Router?.query?.lang) handleRouter({ href: "/", as: "/" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let introTitle =
    getTranslationContent(introModalData, "title", lang) ||
    introModalData?.title;

  let introDesc =
    getTranslationContent(introModalData, "description", lang) ||
    introModalData?.description;

  let introContent =
    getTranslationContent(introModalData, "content", lang) ||
    introModalData?.content;

  useEffect(() => {
    if (
      !modalShown &&
      introModalData &&
      !isEmpty(introModalData) &&
      introDesc &&
      introDesc !== ""
    )
      setTimeout(() => {
        typeof document != "undefined" &&
          document
            ?.getElementById("introModal")
            //@ts-ignore
            ?.showModal();
        setSession({ modalShown: true });
      }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introModalData?._id]);

  return (
    <div className="flex flex-col w-full bg-white">
      <SEO
        title={t("homeTitle").replace("<br/>", "")}
        description={t("homepageDescription")}
      />
      {/**Banner images*/}
      {!news?.news ? null : (
        <Banner
          specialNews={specialNews}
          ads={ads}
          news={news?.news}
          lang={lang}
        />
      )}
      {/**Animated News section*/}
      {news?.news?.length ? (
        <div className="px-[5%] md:px-[8%] my-10">
          <div className="flex flex-col justify-center relative p-5 py-5 rounded-button bg-lightGrey ">
            <div
              className={twMerge(
                "absolute flex",
                lang == "ar" ? "right-7" : "left-7"
              )}
            >
              <Image
                src="/logo.png"
                alt="OEA Logo"
                layout="fixed"
                width={40}
                height={40}
              />
            </div>
            <div className={lang == "ar" ? "mr-20" : "ml-20"}>
              <NewsBar news={news?.news} lang={lang} />
            </div>
          </div>
          <div className="mt-5">
            <MoreInfo href={`/${lang}/news/activities`} title={t("moreNews")} />
          </div>
        </div>
      ) : null}
      {announcements?.news?.length ? (
        <div className={pagePadding}>
          <Text
            bold
            mainColor
            sectionTitle
            text={t("latestAnnouncements")}
          ></Text>
          <div className={"flex flex-col mb-10"}>
            {isArray(announcements?.news) && announcements?.news?.length ? (
              <SliderNews lang={lang} items={announcements?.news} />
            ) : null}
            <div className="mt-4">
              <MoreInfo
                href={`/${lang}/news/announcements/1`}
                title={t("moreAnnouncements")}
              />
            </div>
          </div>
        </div>
      ) : null}
      <DynamicModal
        title={introTitle}
        id={"introModal"}
        lang={lang}
        component={
          <div className="border-t pt-5 border-t-black">
            <Text center infoSize text={introDesc} />
          </div>
        }
        infoComp={
          <div
            className="text-center text-infoText font-[JannaLTBold] text-black pb-3"
            dangerouslySetInnerHTML={{
              __html: introContent,
            }}
          />
        }
      />
    </div>
  );
}

export async function getServerSideProps(context) {
  let { lang } = context.query;
  let requests = [
    {
      key: "news",
      apiName: "news",
      reqBody: {
        limit: 5,
        page: 1,
        stripHtml: true,
        category: "activities",
        lang,
      },
    },

    {
      key: "announcements",
      apiName: "news",
      reqBody: { limit: 4, page: 1, category: "announcements", lang },
    },
    {
      key: "ads",
      apiName: "ads",
      reqBody: { limit: 2, displayIn: "website", status: "published", lang },
    },
    {
      key: "introModalData",
      apiName: "oea-pages-content",
      reqBody: { key: "intro-popup-message", lang },
    },
    {
      key: "specialNews",
      apiName: "get-special-news",
      reqBody: { limit: 1, status: "published", lang },
    },
  ];
  let response = {};
  context.req
    ? (response = await fetchParallel(requests))
    : (response = await fetchFromAPI({ api: "home", reqBody: requests }));
  const {
    news: { response: news },
    announcements: { response: announcements },
    ads: { response: ads },
    introModalData: { response: introModalData },
    specialNews: { response: specialNews },
  } = response;

  return {
    props: {
      news,
      announcements,
      ads,
      introModalData,
      specialNews,
    },
  };
}
