import { getTranslationContent } from "functions";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import Ticker from "react-ticker";

const NewsBar = ({ news = [], lang }) => {
  const [isPaused, setIsPaused] = useState(false);

  return (
    <Ticker
      offset={5}
      move={!isPaused}
      direction={lang == "ar" ? "toRight" : "toLeft"}
    >
      {({ index }) => {
        let isLast = index == news?.length - 1;
        return (
          <Link
            href={`/${lang}/news/article/[slug]`}
            as={`/${lang}/news/article/${news?.[index % news?.length]?.slug}`}
            className="flex flex-row items-center w-72 "
          >
            <a className="flex flex-row items-center ">
              <div className="flex flex-row items-center" >
                <h4
                  className={"mx-10 w-10/12 hover:text-orange text-black text-ellipsis line-clamp-1"}
                  onMouseEnter={() => setIsPaused(true)}
                  onMouseLeave={() => setIsPaused(false)}
                >
                  {getTranslationContent(
                    news?.[index % news?.length],
                    "title",
                    lang
                  )}
                </h4>
                {isLast ? null : (
                      <Image
                        src="/oea.png"
                        alt="OEA Logo"
                        width={20}
                        height={20}
                        className="hidden w-2/12 sm:block mx-5 object-contain opacity-[0.09]"
                      />
                )}
              </div>
            </a>
          </Link>
        );
      }}
    </Ticker>
  );
};

export default NewsBar;
