import React, { useState } from "react";
import HomeSlider from "./HomeSlider";
import { twMerge } from "tailwind-merge";
import { pagePadding } from "data/commonClasses";
import Image from "next/image";
import Modal from "./Modal";

export default function Banner({ news, lang, ads, specialNews }) {
  const [selectedImage, setSelectedImage] = useState("");
  const selectImage = (url) => {
    setSelectedImage(url);
    setTimeout(() => {
      typeof document != "undefined" &&
        document
          ?.getElementById("imageModal")
          //@ts-ignore
          ?.showModal();
    }, 200);
  };
  return (
    <div className={twMerge("w-full", pagePadding)}>
      <div className="flex flex-col-reverse md:flex-row gap-5">
        <div className="w-full md:w-3/12 flex flex-row md:flex-col gap-5">
          <div className="w-1/2 md:w-full aspect-video relative">
            <Image
              src={specialNews?.[0]?.image}
              alt="Banner"
              layout="responsive"
              onClick={() => selectImage(specialNews[0]?.image)}
              width={400}
              height={225}
              className="rounded-button cursor-pointer aspect-video object-cover"
            />
          </div>
          <div className="w-1/2 md:w-full aspect-video relative">
            <Image
              src={ads[0]?.image}
              layout="responsive"
              width={400}
              onClick={() => selectImage(ads[0]?.image)}
              height={225}
              alt="ad-image"
              className="aspect-video rounded-button object-cover cursor-pointer"
              // onClick={() =>
              //   typeof document !== "undefined" &&
              //   document.getElementById("adsModal")?.showModal()
              // }
            />
          </div>
        </div>
        <div className="w-full md:w-9/12 aspect-[2.6] z-0">
          <HomeSlider
            showTitle
            items={news?.filter((item) => !!item?.image)}
            lang={lang}
          />
        </div>
      </div>
      <Modal
        // title={"إعلان"}
        id={"imageModal"}
        lang={lang}
        image
        component={
          selectedImage && (
            <Image
              src={selectedImage}
              layout="responsive"
              width={400}
              height={225}
              className="aspect-video rounded-button object-cover cursor-pointer"
              // onClick={() =>
              //   typeof document !== "undefined" &&
              //   document.getElementById("adsModal")?.showModal()
              // }
            />
          )
        }
        // info="نظراً للوضع القائم، قرر مجلس نقابة المهندسين للمرة الاخيرة تمديد مهلة اختيار البرنامج، تسديد الاشتراكات السنوية والفروقات للمهندسين وعائلاتهم (الزوجة/الزوج والأولاد فقط)، دون غرامات تأخير وذلك حتى 31/8/2024، مع تأمين التغطية الصحية الاستشفائية في الحالات الطارئة. يمكن تسديد هذه الاشتراكات مباشرة في مراكز النقابة في بيروت والمناطق وعبر موقع النقابة وشركتي OMT وBOBFinance. ويرجى اخذ العلم انه ابتداءً من تاريخ 1/9/2024 تطبق فترة تدرج ثلاثة أشهر حسب نظام الاستشفاء على عائلة المهندس/المهندسة أي الزوجة/الزوج والأولاد. للإطلاع على البرامج الإستشفائية: إضغط هنا"
      />
    </div>
  );
}
